@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .form-input {
        @apply block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border-[1px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600
    }
    .form-label {
        @apply absolute text-sm text-gray-500 duration-300 transform bg-white -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1
    }
    .required {
        @apply after:content-['*'] after:ml-1 after:text-red-500
    }
    .btn-sidebar-active{
        @apply text-left p-2 my-1 bg-[#222222] rounded text-white shadow-xl font-bold transition-all ease-in-out hover:scale-105 w-48
    }
    .btn-main {
        @apply bg-[#222222] text-white w-80 font-bold p-2 rounded mt-2 hover:scale-105 transition-all ease-in-out
    }
    .btn-sidebar{
        @apply text-left p-2 w-48 my-1 hover:bg-gray-200 rounded z-10 transition-all ease-in-out hover:scale-105
    }
}